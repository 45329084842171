﻿@import "../ui-variables";

.win-tile {
    display: block;
    cursor: pointer;
    min-width: 70px;
    min-height: 70px;
    text-align: center;
    /*background: #0078D7;*/
    /*color: $color-white;*/
    z-index: 1;
    border: 2px rgba(0,0,0,0) solid;

    /*box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.25) !important;*/

    &.win-tile-chartsmall {
        height: 300px;
        width: 400px;
    }

    &.win-tile-chartlarge {
        height: 300px;
        width: 600px;
    }


    &.win-tile-medium {
        height: 150px;
        width: 150px;
    }

    &.win-tile-small {
        height: 70px;
        width: 70px;

        & .tile-icon {
            margin-top: 6px;
        }
    }

    &.win-tile-wide {
        height: 150px;
        width: 310px;
    }

    &:hover {
        border: 2px @hover-color solid;
    }

    &:active, &.active {
        border: 2px @active-color solid;
    }



    a {
        /*color: $color-white;*/
        text-decoration: none;
    }

    /*div {
        margin-top: 1em;
    }*/


    .win-tile-icon {
        font-size: 4em;
        margin-top: 23px;
        color:white;
    }

    .win-tile-title {
        color:white;
    }



    .win-tile-notification-right {
        position:absolute;
        left:0;
        top:0;
        padding-left: 16px;
        color:white;
    }

    .win-tile-notification-left {
        position:absolute;
        right:0;
        top:0;
        padding-right: 16px;
        color:white;
    }
}
